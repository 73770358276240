import React from 'react';
import PropTypes from 'prop-types';

import { isArrayWithLength } from '../../shared/utils/validators';

import './tab-as-header.scss';

function TabAsHeader(props) {
  const {
    className,
    tabs = [],
    selectedTab,
    onTabSelect,
    trailingContent
  } = props;

  return (
    <div className={`tab-as-header ${className || ''}`} styleName="header">
      <div className="tab-as-header__list" role="tablist" styleName="tab-list">
        {isArrayWithLength(tabs) &&
          tabs.map(tab => (
            <button
              aria-selected={tab === selectedTab}
              className="tab-as-header__tab"
              key={`tab-${tab}`}
              onClick={() => onTabSelect(tab)}
              role="tab"
              styleName={tab === selectedTab ? 'tab--selected' : 'tab'}
            >
              {tab}
            </button>
          ))}
      </div>
      {typeof trailingContent === 'function' && trailingContent()}
    </div>
  );
}

const tabPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.object]);

TabAsHeader.propTypes = {
  className: PropTypes.string,
  onTabSelect: PropTypes.func,
  selectedTab: tabPropType,
  tabs: PropTypes.arrayOf(tabPropType),
  trailingContent: PropTypes.func
};

export default React.memo(TabAsHeader);
