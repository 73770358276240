import React, { forwardRef } from 'react';

import './typography.scss';

const WEIGHTS = { BLACK: 'black', HEAVY: 'heavy' } as const;

const SIZES = {
  EXTRASMALL: 'extraSmall',
  REGULAR: 'regular',
  SMALL: 'small'
} as const;

export interface SizeProps {
  size?: typeof SIZES[keyof typeof SIZES];
}
export interface WeightProps {
  weight?: typeof WEIGHTS[keyof typeof WEIGHTS];
}

export interface VariantProps {
  variant?: 'H1' | 'H2' | 'H3' | 'H4' | 'H5' | 'H6' | 'P';
}

//TODO: Find an appropriate type to replace React.ComponentProps<any> to allow type checking for html attribute props. So far I tried various types, but this was the only one which didn't complain trying to support any html element attribute types for the 'tag' prop.
export interface TypographyProps
  extends React.ComponentProps<any>,
    WeightProps,
    VariantProps,
    SizeProps {
  tag?: keyof JSX.IntrinsicElements;
}

function getWeightStyleName(variant, weight) {
  if (weight === WEIGHTS.HEAVY) {
    return `${variant}-heavy`;
  } else if (weight === WEIGHTS.BLACK) {
    return `${variant}-black`;
  } else {
    return `${variant}-medium`;
  }
}

function getSizeStyleName(variant, size) {
  if (variant === 'p') {
    if (size === SIZES.SMALL) {
      return `${variant}-small`;
    } else if (size === SIZES.EXTRASMALL) {
      return `${variant}-extra-small`;
    } else {
      return `${variant}-regular`;
    }
  }
  return '';
}

const Typography = forwardRef(function Typography(
  props: React.PropsWithChildren<TypographyProps>,
  ref
) {
  const { children, size, weight, tag, variant = 'P', ...rest } = props;

  const variantLowerCase = variant.toLowerCase();
  const Tag: any = tag || variantLowerCase;

  return (
    <Tag
      ref={ref}
      styleName={`${variantLowerCase} ${getSizeStyleName(
        variantLowerCase,
        size
      )} ${getWeightStyleName(variantLowerCase, weight)}`}
      {...rest}
    >
      {children}
    </Tag>
  );
});

export default Typography;
